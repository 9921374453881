.video-first,
.video-second {
    z-index: 1;

    iframe {

        @media #{$xsmall} {
            width: 250px;
            height: 200px;
        }

        @media screen and (min-width: 400px) {
            width: 300px;
            height: 250px;
        }

        @media #{$mid} {
            width: 400px;
            height: 300px;
        }

        // @media #{$large} {
        //     width: 550px;
        // }

        @media #{$xlarge} {
            width: 650px;
            height: 370px;
        }
    }
}

.video-first {
    @media #{$large} {
        margin-bottom: 10%;
    }
}

.video-second {
    @media #{$large} {
        margin-left: 12%;
    }
}