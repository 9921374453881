.landing-header {
    height: 250px;
    background-image: url('../images/wood.png');
    background-size: cover;
    position: relative;

    margin-bottom: 100px;

    @media #{$small} {
        margin-bottom: 20px;
        height: 400px;
    }

    @media #{$mid} {
        height: 620px;
        margin-bottom: 0;
    }

    &__title {
        font-family: LeRustique;
        font-weight: 400;
        letter-spacing: 0.015em;
        color: white;
        font-size: 50px;
        padding-top: 80px;
        padding-left: 10px;
        text-align: center;
        z-index: 10000;
        text-shadow: 0.1em 0.1em 0.2em #2d251f;
        // padding-right: 40px;

        @media #{$small} {
            text-align: center;
            font-size: 80px;
            padding-top: 165px;
            padding-left: 30px;
        }

        @media #{$mid} {
            padding-right: 0;
            text-align: initial;
            font-size: 100px;
            padding-left: 25%;
            padding-top: 250px;
        }

        @media #{$large} {
            font-size: 120px;
            padding-top: 200px;
            padding-left: 17%;
        }

        @media #{$xlarge} {
            padding-left: 13.5%;
        }

    }

    &__background {
        height: 100%;
        background-image: none;
        background-repeat: no-repeat;
        background-position: 120% 0;
        position: relative;

        @media #{$mid} {
            background-image: url('../images/backgroundhead.png');
            background-size: 40%;
        }

        @media #{$large} {
            background-size: 43%;
        }
        @media #{$xlarge} {
            background-position: 105% 0;
            background-size: initial;
        }

        &:before {
            // ORNAMENTS
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background-image: url('../images/ornaments.png');
            background-repeat: no-repeat;
            background-size: 130%;
            width: 100%;
            height: 100%;
            z-index: 1000;

            @media #{$small} {
                background-size: 170%;
                z-index: 1000;
            }

            @media #{$mid} {
                width: 720px;
                height: 617px;
                background-size: initial;
            }

            @media #{$large} {
                width: 900px;
            }
        }
    }

    &__img {
        display: none;

        @media #{$large} {
            display: block;
            position: absolute;

            &--flag {
                left: initial;
                bottom: 0;
                right: 0;
                z-index: 1000;
            }
        }

        &--product {
            display: block;
            width: 300px;
            z-index: 10000;
            display: block;
            position: absolute;
            bottom: -30%;
            right: 5%;

            @media screen and (min-width: 440px) {
                bottom: -47%;
            }

            @media #{$small} {
                width: 50%;
                left: 25%;
                bottom: -100px;
            }

            @media #{$mid} {
                width: calc(auto - 10%);
                left: 25%;
            }

            @media #{$large} {
                left: 13%;
                width: auto;
                bottom: -90px;
            }
        }
    }
}