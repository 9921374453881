.landing-section {
    padding-top: 50px;
    position: relative;

    &__header {
        display: flex;
        flex-direction: column;
        position: relative;
        padding-bottom: 50px;
        z-index: 2000;

        @media #{$large} {
            flex-direction: row;
        }

        &:after {
            @media #{$large} {
                content: "";
                position: absolute;
                right: 20px;
                top: 50px;
                width: 180px;
                height: 400px;
                background-image: url('../images/title-stars.png');
                background-repeat: no-repeat;
                z-index: 0;
            }
        }

        &--diff {
            padding-bottom: 20px;

            &:after {
                @media #{$large} {
                    content: "";
                    position: absolute;
                    // right: -15px;
                    top: -35px;
                    width: 120px;
                    height: 400px;
                    background-image: url('../images/title-stars2.png');
                    background-repeat: no-repeat;
                    z-index: 0;
                }
            }
        }

        &--diff2 {
            padding-bottom: 20px;

            &:after {
                @media #{$large} {
                    content: "";
                    position: absolute;
                    // right: -120px;
                    top: 15px;
                    width: 200px;
                    height: 400px;
                    background-image: url('../images/title-stars3.png');
                    background-repeat: no-repeat;
                    z-index: 0;
                }
            }
        }

        &--nopd {
            padding: 0;
        }
    }

    &__label {
        background: url('../images/label.png');
        background-repeat: no-repeat;
        background-position: 0px 0px;
        min-height: 100px;

        &--inner {
            .landing-section__label-title {
                padding: 30px 90px 0 110px;
            }
        }

        &-title {
            text-transform: uppercase;
            padding: 30px 60px 0 90px;
            font-family: LeRustique;
            letter-spacing: 0.1em;
            font-size: 28px;
            font-weight: 400;
            color: white;

        }
    }

    &__title {
        text-transform: uppercase;
        font-family: LeRustique;
        font-weight: 400;
        padding: 20px 20px 14px 30px;
        font-size: 30px;
        letter-spacing: 0.05em;
        align-self: center;
        color: #442e2e;

        @media #{$large} {
            padding: 0 20px 14px 10px;
        }
    }

    &__content {
        position: relative;
        padding: 0 15px 20px 15px;

        &:before {
            @media #{$mid} {
                content: "";
                position: absolute;
                left: 20px;
                top: -35px;
                background-image: url('../images/sideStars.png');
                height: 105%;
                width: 50px;
                z-index: 0;
            }
        }

        &:after {
            @media #{$large} {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 200px;
                z-index: 1;
                background-image: url('../images/section-bottom.png');
                background-repeat: no-repeat;
                // background-size: 100%;
            }
        }



        &--logo {
            width: 65% !important;
            height: auto !important;
            margin: 0 15%;

            @media #{$large} {
                padding-top: 20px;
                margin: 0 15% 30px 15%;
            }
        }

        &--secondLogo {
            width: 40% !important;
            height: auto !important;
            margin: 0 28%;

            @media #{$large} {
                padding-top: 20px;
                margin: 0 0 30px 28%;
            }
        }

        p {
            font-size: 1.8em;
            max-width: 400px;
        }

        .video {
            position: relative;

            iframe {
                z-index: 1000;
            }

            &-large {
                &:before {
                    content: "";
                    position: absolute;
                    bottom: 30px;
                    left: -23px;
                    z-index: -1;
                    background-color: #d2272b;
                    width: 100%;
                    height: 90%;
                    transform: rotate(-3deg);

                    @media #{$mid} {
                        content: "";
                        position: absolute;
                        bottom: 35px;
                        left: -23px;
                        z-index: -1;
                        background-color: #d2272b;
                        width: 100%;
                        height: 90%;
                        transform: rotate(-3deg);
                    }

                    @media #{$large} {
                        bottom: 5px;
                        left: -23px;
                        height: 90%;
                    }
                }

                &--second {
                    &:before {
                        bottom: -5px;
                        left: 10px;
                        transform: rotate(-3deg);
                        height: 95%;
                    }

                    @media #{$mid} {
                        &:before {
                            left: 15px;
                        }
                    }
                }
            }
        }
    }

    &__text {
        h2 {
            text-align: center;
            text-transform: uppercase;
        }

        p {
            font-size: 36px;
            line-height: 1.2em;
            padding: 10px;

            @media #{$mid} {
                padding: 20px 0;
            }

            @media #{$large} {
                padding: 0 10px;
                padding-left: 30px;
                max-width: 450px;
            }
        }

        img {
            padding-left: 40px;
            padding-bottom: 30px;

            @media #{$large} {
                margin-top: 20px;
            }

            @media #{$xlarge} {
                padding-left: 20px;
            }
        }
    }

    &__link {
        text-decoration: none;
        font-size: 20px;
        position: relative;
        z-index: 2;
        padding-left: 30px;

        &:after {
            position: absolute;
            width: 50px;
            height: 25px;
            background-image: url('../images/arrow.png');
            background-repeat: no-repeat;

            @media #{$small} {
                content: "";
                right: -60px;
                bottom: 40%;
            }
        }

        &--mini {
            &:after {
                right: 20% !important;
            }
        }

        p {
            color: #442e2e;
        }
    }

    &__container {
        padding-top: 35px;
    }

    &__img {
        margin-top: -10px;
        margin-left: 5px;
        padding-bottom: 20px;

        @media #{$xsmall} {
            max-width: 300px;
        }
    }

    &-small {

        .container-column {
            // padding-right: 50px;
            padding-bottom: 10px;

            &:first-child {
                @media #{$large} {
                    padding-right: 20%;
                }
            }

            img {
                width: 100%;
                position: relative;

                @media #{$small} {
                    width: 380px;
                    height: 285px;
                }
            }
        }

        .landing-section__content {
            @media #{$large} {
                padding-bottom: 80px;
            }
            p {
                max-width: 400px;
                padding: 0;
            }

            .image {
                position: relative;
                margin: 0 auto;

                &:after {
                    @media #{$large} {
                        content: "";
                        position: absolute;
                        top: 50px;
                        right: -45%;
                        width: 100px;
                        height: 200px;
                        background-image: url('../images/imgStars.png');
                        background-repeat: no-repeat;
                    }
                }
            }


            .container {
                display: flex;
                max-width: 1080px;
                margin: 0 auto;
            }

            .landing-section__text {
                padding-bottom: 10px;
                min-height: 90px;

                h2 {
                    font-family: LeRustique;
                    font-size: 22px;
                    font-weight: 400;
                    padding-top: 8px;
                }

                p {
                    font-size: 24px;
                    padding-top: 0;
                    text-align: center;
                    padding-left: 0px;
                }
            }
        }

        .landing-section__link {
            display: block;
            padding-left: 0;

            &--mini:after {
                right: 20px;
                top: 12px;
            }
        }
    }

    &-last {
        .landing-section__content {
            padding-bottom: 70px;
            @media #{$large} {
                padding-bottom: 200px;
            }
        }

        .landing-section__link {
            padding-left: 0;
            padding-bottom: 20px;
        }

        .container {
            max-width: 1080px;
            margin: 0 auto;

            div p {
                line-height: 1.3;
                margin-top: 10px;
                font-size: 26px;
            }

            a {
                display: block;

                &:after {
                    right: -10px;
                }
            }

            .image {
                width: 90%;
                padding-right: 30px;
                margin-top: 30px;

                @media #{$small} {
                    width: 450px;
                    height: 400px;
                }

                @media #{$large} {
                    margin-top: 0;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                &:before {
                    height: 90%;
                    top: 25px;
                    left: -20px;
                    transform: rotate(-3deg);

                    @media #{$small} {
                        top: 15px;
                        left: -40px;
                        height: 100%;
                        transform: rotate(-3deg);
                    }
                }
            }
        }
    }
}

.image {
    position: relative;
    width: 85%;
    height: auto;
    z-index: 1;

    @media #{$small} {
        width: 400px;
        height: 300px;
    }

    &:before {
        content: "";
        position: absolute;
        top: -5px;
        right: 5px;
        z-index: -1;
        background-color: #d2272b;
        width: 95%;
        height: 93%;
        // box-shadow: -10px 10px 0 4px #d2272b;
        transform: rotate(3deg);
    }

    &--second {
        &:before {
            top: initial;
            right: initial;
            bottom: 5px;
            left: -10px;
            // height: 90%;
            transform: rotate(-2deg);
        }

        &:after {
            content: none !important;
        }
    }
}

.landing-section-third{
    .landing-section__text .landing-section__img{
        @media #{$large} {
            margin-top: 30px;
        }
    }
}

.section-links {
    display: flex;
    text-decoration: none;
    font-size: 20px;
    position: relative;
    z-index: 2;
    color: #442e2e;

    &-col {
        display: block;
    }

    .landing-section__img {
        margin-top: -10px;
        margin-left: 5px;
        padding-bottom: 20px;
    }

    p {
        text-decoration: none;
        position: relative;
        z-index: 2;
        padding-left: 30px;

        &.arrowLink:after {
            position: absolute;
            width: 50px;
            height: 25px;
            background-image: url('../images/arrow.png');
            background-repeat: no-repeat;

            @media #{$small} {
                content: "";
                right: -60px;
                bottom: 50%;
            }
        }
    }
}

.arrow-relative {
    position: relative;

    &:after {
        position: absolute;
        width: 50px;
        height: 25px;
        background-image: url('../images/arrow.png');
        background-repeat: no-repeat;
        content: "";
        right: 10% !important;
        bottom: 30% !important;
    }

    &--mgleft {
        margin-left: 10%;
    }

    &--last {
        &:after {
            @media #{$small} {
                right: 140px !important;
            }
        }
    }
    
}