@font-face {
  font-family: LeRustique;
  src: url(../fonts/LeRustiqueSmallCaps_regular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Indie Flower';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/IndieFlower.woff2) format("woff2");
}

.landing-header {
  height: 250px;
  background-image: url("../images/wood.png");
  background-size: cover;
  position: relative;
  margin-bottom: 100px;
}

@media screen and (min-width: 576px) {
  .landing-header {
    margin-bottom: 20px;
    height: 400px;
  }
}

@media screen and (min-width: 768px) {
  .landing-header {
    height: 620px;
    margin-bottom: 0;
  }
}

.landing-header__title {
  font-family: LeRustique;
  font-weight: 400;
  letter-spacing: 0.015em;
  color: white;
  font-size: 50px;
  padding-top: 80px;
  padding-left: 10px;
  text-align: center;
  z-index: 10000;
  text-shadow: 0.1em 0.1em 0.2em #2d251f;
}

@media screen and (min-width: 576px) {
  .landing-header__title {
    text-align: center;
    font-size: 80px;
    padding-top: 165px;
    padding-left: 30px;
  }
}

@media screen and (min-width: 768px) {
  .landing-header__title {
    padding-right: 0;
    text-align: initial;
    font-size: 100px;
    padding-left: 25%;
    padding-top: 250px;
  }
}

@media screen and (min-width: 992px) {
  .landing-header__title {
    font-size: 120px;
    padding-top: 200px;
    padding-left: 17%;
  }
}

@media screen and (min-width: 1200px) {
  .landing-header__title {
    padding-left: 13.5%;
  }
}

.landing-header__background {
  height: 100%;
  background-image: none;
  background-repeat: no-repeat;
  background-position: 120% 0;
  position: relative;
}

@media screen and (min-width: 768px) {
  .landing-header__background {
    background-image: url("../images/backgroundhead.png");
    background-size: 40%;
  }
}

@media screen and (min-width: 992px) {
  .landing-header__background {
    background-size: 43%;
  }
}

@media screen and (min-width: 1200px) {
  .landing-header__background {
    background-position: 105% 0;
    background-size: initial;
  }
}

.landing-header__background:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/ornaments.png");
  background-repeat: no-repeat;
  background-size: 130%;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

@media screen and (min-width: 576px) {
  .landing-header__background:before {
    background-size: 170%;
    z-index: 1000;
  }
}

@media screen and (min-width: 768px) {
  .landing-header__background:before {
    width: 720px;
    height: 617px;
    background-size: initial;
  }
}

@media screen and (min-width: 992px) {
  .landing-header__background:before {
    width: 900px;
  }
}

.landing-header__img {
  display: none;
}

@media screen and (min-width: 992px) {
  .landing-header__img {
    display: block;
    position: absolute;
  }
  .landing-header__img--flag {
    left: initial;
    bottom: 0;
    right: 0;
    z-index: 1000;
  }
}

.landing-header__img--product {
  display: block;
  width: 300px;
  z-index: 10000;
  display: block;
  position: absolute;
  bottom: -30%;
  right: 5%;
}

@media screen and (min-width: 440px) {
  .landing-header__img--product {
    bottom: -47%;
  }
}

@media screen and (min-width: 576px) {
  .landing-header__img--product {
    width: 50%;
    left: 25%;
    bottom: -100px;
  }
}

@media screen and (min-width: 768px) {
  .landing-header__img--product {
    width: calc(auto - 10%);
    left: 25%;
  }
}

@media screen and (min-width: 992px) {
  .landing-header__img--product {
    left: 13%;
    width: auto;
    bottom: -90px;
  }
}

.landing-section {
  padding-top: 50px;
  position: relative;
}

.landing-section__header {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 50px;
  z-index: 2000;
}

@media screen and (min-width: 992px) {
  .landing-section__header {
    flex-direction: row;
  }
}

@media screen and (min-width: 992px) {
  .landing-section__header:after {
    content: "";
    position: absolute;
    right: 20px;
    top: 50px;
    width: 180px;
    height: 400px;
    background-image: url("../images/title-stars.png");
    background-repeat: no-repeat;
    z-index: 0;
  }
}

.landing-section__header--diff {
  padding-bottom: 20px;
}

@media screen and (min-width: 992px) {
  .landing-section__header--diff:after {
    content: "";
    position: absolute;
    top: -35px;
    width: 120px;
    height: 400px;
    background-image: url("../images/title-stars2.png");
    background-repeat: no-repeat;
    z-index: 0;
  }
}

.landing-section__header--diff2 {
  padding-bottom: 20px;
}

@media screen and (min-width: 992px) {
  .landing-section__header--diff2:after {
    content: "";
    position: absolute;
    top: 15px;
    width: 200px;
    height: 400px;
    background-image: url("../images/title-stars3.png");
    background-repeat: no-repeat;
    z-index: 0;
  }
}

.landing-section__header--nopd {
  padding: 0;
}

.landing-section__label {
  background: url("../images/label.png");
  background-repeat: no-repeat;
  background-position: 0px 0px;
  min-height: 100px;
}

.landing-section__label--inner .landing-section__label-title {
  padding: 30px 90px 0 110px;
}

.landing-section__label-title {
  text-transform: uppercase;
  padding: 30px 60px 0 90px;
  font-family: LeRustique;
  letter-spacing: 0.1em;
  font-size: 28px;
  font-weight: 400;
  color: white;
}

.landing-section__title {
  text-transform: uppercase;
  font-family: LeRustique;
  font-weight: 400;
  padding: 20px 20px 14px 30px;
  font-size: 30px;
  letter-spacing: 0.05em;
  align-self: center;
  color: #442e2e;
}

@media screen and (min-width: 992px) {
  .landing-section__title {
    padding: 0 20px 14px 10px;
  }
}

.landing-section__content {
  position: relative;
  padding: 0 15px 20px 15px;
}

@media screen and (min-width: 768px) {
  .landing-section__content:before {
    content: "";
    position: absolute;
    left: 20px;
    top: -35px;
    background-image: url("../images/sideStars.png");
    height: 105%;
    width: 50px;
    z-index: 0;
  }
}

@media screen and (min-width: 992px) {
  .landing-section__content:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    z-index: 1;
    background-image: url("../images/section-bottom.png");
    background-repeat: no-repeat;
  }
}

.landing-section__content--logo {
  width: 65% !important;
  height: auto !important;
  margin: 0 15%;
}

@media screen and (min-width: 992px) {
  .landing-section__content--logo {
    padding-top: 20px;
    margin: 0 15% 30px 15%;
  }
}

.landing-section__content--secondLogo {
  width: 40% !important;
  height: auto !important;
  margin: 0 28%;
}

@media screen and (min-width: 992px) {
  .landing-section__content--secondLogo {
    padding-top: 20px;
    margin: 0 0 30px 28%;
  }
}

.landing-section__content p {
  font-size: 1.8em;
  max-width: 400px;
}

.landing-section__content .video {
  position: relative;
}

.landing-section__content .video iframe {
  z-index: 1000;
}

.landing-section__content .video-large:before {
  content: "";
  position: absolute;
  bottom: 30px;
  left: -23px;
  z-index: -1;
  background-color: #d2272b;
  width: 100%;
  height: 90%;
  transform: rotate(-3deg);
}

@media screen and (min-width: 768px) {
  .landing-section__content .video-large:before {
    content: "";
    position: absolute;
    bottom: 35px;
    left: -23px;
    z-index: -1;
    background-color: #d2272b;
    width: 100%;
    height: 90%;
    transform: rotate(-3deg);
  }
}

@media screen and (min-width: 992px) {
  .landing-section__content .video-large:before {
    bottom: 5px;
    left: -23px;
    height: 90%;
  }
}

.landing-section__content .video-large--second:before {
  bottom: -5px;
  left: 10px;
  transform: rotate(-3deg);
  height: 95%;
}

@media screen and (min-width: 768px) {
  .landing-section__content .video-large--second:before {
    left: 15px;
  }
}

.landing-section__text h2 {
  text-align: center;
  text-transform: uppercase;
}

.landing-section__text p {
  font-size: 36px;
  line-height: 1.2em;
  padding: 10px;
}

@media screen and (min-width: 768px) {
  .landing-section__text p {
    padding: 20px 0;
  }
}

@media screen and (min-width: 992px) {
  .landing-section__text p {
    padding: 0 10px;
    padding-left: 30px;
    max-width: 450px;
  }
}

.landing-section__text img {
  padding-left: 40px;
  padding-bottom: 30px;
}

@media screen and (min-width: 992px) {
  .landing-section__text img {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .landing-section__text img {
    padding-left: 20px;
  }
}

.landing-section__link {
  text-decoration: none;
  font-size: 20px;
  position: relative;
  z-index: 2;
  padding-left: 30px;
}

.landing-section__link:after {
  position: absolute;
  width: 50px;
  height: 25px;
  background-image: url("../images/arrow.png");
  background-repeat: no-repeat;
}

@media screen and (min-width: 576px) {
  .landing-section__link:after {
    content: "";
    right: -60px;
    bottom: 40%;
  }
}

.landing-section__link--mini:after {
  right: 20% !important;
}

.landing-section__link p {
  color: #442e2e;
}

.landing-section__container {
  padding-top: 35px;
}

.landing-section__img {
  margin-top: -10px;
  margin-left: 5px;
  padding-bottom: 20px;
}

@media screen and (max-width: 575px) {
  .landing-section__img {
    max-width: 300px;
  }
}

.landing-section-small .container-column {
  padding-bottom: 10px;
}

@media screen and (min-width: 992px) {
  .landing-section-small .container-column:first-child {
    padding-right: 20%;
  }
}

.landing-section-small .container-column img {
  width: 100%;
  position: relative;
}

@media screen and (min-width: 576px) {
  .landing-section-small .container-column img {
    width: 380px;
    height: 285px;
  }
}

@media screen and (min-width: 992px) {
  .landing-section-small .landing-section__content {
    padding-bottom: 80px;
  }
}

.landing-section-small .landing-section__content p {
  max-width: 400px;
  padding: 0;
}

.landing-section-small .landing-section__content .image {
  position: relative;
  margin: 0 auto;
}

@media screen and (min-width: 992px) {
  .landing-section-small .landing-section__content .image:after {
    content: "";
    position: absolute;
    top: 50px;
    right: -45%;
    width: 100px;
    height: 200px;
    background-image: url("../images/imgStars.png");
    background-repeat: no-repeat;
  }
}

.landing-section-small .landing-section__content .container {
  display: flex;
  max-width: 1080px;
  margin: 0 auto;
}

.landing-section-small .landing-section__content .landing-section__text {
  padding-bottom: 10px;
  min-height: 90px;
}

.landing-section-small .landing-section__content .landing-section__text h2 {
  font-family: LeRustique;
  font-size: 22px;
  font-weight: 400;
  padding-top: 8px;
}

.landing-section-small .landing-section__content .landing-section__text p {
  font-size: 24px;
  padding-top: 0;
  text-align: center;
  padding-left: 0px;
}

.landing-section-small .landing-section__link {
  display: block;
  padding-left: 0;
}

.landing-section-small .landing-section__link--mini:after {
  right: 20px;
  top: 12px;
}

.landing-section-last .landing-section__content {
  padding-bottom: 70px;
}

@media screen and (min-width: 992px) {
  .landing-section-last .landing-section__content {
    padding-bottom: 200px;
  }
}

.landing-section-last .landing-section__link {
  padding-left: 0;
  padding-bottom: 20px;
}

.landing-section-last .container {
  max-width: 1080px;
  margin: 0 auto;
}

.landing-section-last .container div p {
  line-height: 1.3;
  margin-top: 10px;
  font-size: 26px;
}

.landing-section-last .container a {
  display: block;
}

.landing-section-last .container a:after {
  right: -10px;
}

.landing-section-last .container .image {
  width: 90%;
  padding-right: 30px;
  margin-top: 30px;
}

@media screen and (min-width: 576px) {
  .landing-section-last .container .image {
    width: 450px;
    height: 400px;
  }
}

@media screen and (min-width: 992px) {
  .landing-section-last .container .image {
    margin-top: 0;
  }
}

.landing-section-last .container .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.landing-section-last .container .image:before {
  height: 90%;
  top: 25px;
  left: -20px;
  transform: rotate(-3deg);
}

@media screen and (min-width: 576px) {
  .landing-section-last .container .image:before {
    top: 15px;
    left: -40px;
    height: 100%;
    transform: rotate(-3deg);
  }
}

.image {
  position: relative;
  width: 85%;
  height: auto;
  z-index: 1;
}

@media screen and (min-width: 576px) {
  .image {
    width: 400px;
    height: 300px;
  }
}

.image:before {
  content: "";
  position: absolute;
  top: -5px;
  right: 5px;
  z-index: -1;
  background-color: #d2272b;
  width: 95%;
  height: 93%;
  transform: rotate(3deg);
}

.image--second:before {
  top: initial;
  right: initial;
  bottom: 5px;
  left: -10px;
  transform: rotate(-2deg);
}

.image--second:after {
  content: none !important;
}

@media screen and (min-width: 992px) {
  .landing-section-third .landing-section__text .landing-section__img {
    margin-top: 30px;
  }
}

.section-links {
  display: flex;
  text-decoration: none;
  font-size: 20px;
  position: relative;
  z-index: 2;
  color: #442e2e;
}

.section-links-col {
  display: block;
}

.section-links .landing-section__img {
  margin-top: -10px;
  margin-left: 5px;
  padding-bottom: 20px;
}

.section-links p {
  text-decoration: none;
  position: relative;
  z-index: 2;
  padding-left: 30px;
}

.section-links p.arrowLink:after {
  position: absolute;
  width: 50px;
  height: 25px;
  background-image: url("../images/arrow.png");
  background-repeat: no-repeat;
}

@media screen and (min-width: 576px) {
  .section-links p.arrowLink:after {
    content: "";
    right: -60px;
    bottom: 50%;
  }
}

.arrow-relative {
  position: relative;
}

.arrow-relative:after {
  position: absolute;
  width: 50px;
  height: 25px;
  background-image: url("../images/arrow.png");
  background-repeat: no-repeat;
  content: "";
  right: 10% !important;
  bottom: 30% !important;
}

.arrow-relative--mgleft {
  margin-left: 10%;
}

@media screen and (min-width: 576px) {
  .arrow-relative--last:after {
    right: 140px !important;
  }
}

.video-first,
.video-second {
  z-index: 1;
}

@media screen and (max-width: 575px) {
  .video-first iframe,
  .video-second iframe {
    width: 250px;
    height: 200px;
  }
}

@media screen and (min-width: 400px) {
  .video-first iframe,
  .video-second iframe {
    width: 300px;
    height: 250px;
  }
}

@media screen and (min-width: 768px) {
  .video-first iframe,
  .video-second iframe {
    width: 400px;
    height: 300px;
  }
}

@media screen and (min-width: 1200px) {
  .video-first iframe,
  .video-second iframe {
    width: 650px;
    height: 370px;
  }
}

@media screen and (min-width: 992px) {
  .video-first {
    margin-bottom: 10%;
  }
}

@media screen and (min-width: 992px) {
  .video-second {
    margin-left: 12%;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
}

.container-center {
  justify-content: center;
  align-items: center;
}

.container-column {
  flex-direction: column;
  padding-bottom: 40px;
}

.container-column a, .container-column p {
  text-decoration: none;
  color: #442e2e;
  transition: 0.3s;
}

.container-around {
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 992px) {
  .container-around {
    flex-direction: row;
    justify-content: space-around;
  }
}

.container-pdtop {
  padding-top: 30px;
}

@media screen and (min-width: 992px) {
  .container-pdtop {
    padding-top: 0;
  }
}

body {
  font-family: 'Source Sans Pro', sans-serif;
}

body p {
  font-family: 'Indie Flower', cursive;
  color: #442e2e;
}

.wrapper {
  background-image: url("../images/background.png");
  background-size: cover;
  max-width: 1366px;
  margin: 0 auto;
}
