@font-face {
  font-family: LeRustique;
  src: url(../fonts/LeRustiqueSmallCaps_regular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Indie Flower';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/IndieFlower.woff2) format('woff2');
}

@import "variables";

@import 'partials/header.scss';
@import 'partials/section.scss';
@import 'partials/video.scss';


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  padding: 0 15px;
  display: flex;
  justify-content: space-between;

  &-center {
    justify-content: center;
    align-items: center;
  }

  &-column {
    flex-direction: column;
    padding-bottom: 40px;

    a, p {
      text-decoration: none;
      color: #442e2e;
      transition: 0.3s;
    }
  }
  &-around{
    flex-direction: column;
    align-items: center;

    @media #{$large} {
      flex-direction: row;
      justify-content: space-around;
    }
  }
  &-pdtop{
    padding-top: 30px;
    @media #{$large} {
      padding-top: 0;
    }
  }
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  p {
    font-family: 'Indie Flower', cursive;
    color: #442e2e;
  }
}

.wrapper{
  background-image: url('../images/background.png');
  background-size: cover;
  max-width: 1366px;
  margin: 0 auto;
}